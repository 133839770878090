import React, {
  ChangeEvent,
  createContext,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  selectInstructionsDisplayed,
  setFullColorData,
  setInstructionsDisplayed,
} from "./color-picker-slice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utils/hooks/redux-hooks";

type CanvasSize = "desktop" | "mobile";
interface ImageInterface {
  img: HTMLImageElement | null | undefined;
  url: string;
  drawCanvas: (
    canvas: RefObject<HTMLCanvasElement>,
    url: string,
    canvasSize: CanvasSize,
    isKiosk: boolean,

  ) => void;
  fileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  getPixelData: (
    canvas: RefObject<HTMLCanvasElement>,
    zoomCanvas: RefObject<HTMLCanvasElement>,
    canvasSize: CanvasSize,
    isKiosk: boolean
  ) => void;
  magX: number;
  magY: number;
}

type Props = {
  children?: React.ReactNode;
};

export const ImageContext = createContext<ImageInterface>({
  img: null,
  drawCanvas: () => { },
  fileUpload: () => { },
  url: "",
  getPixelData: () => { },
  magX: 0,
  magY: 0,
});

var mouseIsDown = false;
const magnifierSize: number = 125;
const selectionSize: number = 50;

export const ImageProvider = ({ children }: Props) => {
  const [img, setImg] = useState<HTMLImageElement>();
  const [url, setUrl] = useState<string>("");
  const [color, setColor] = useState<string>("transparent");
  const [magX, setMagX] = useState<number>(0);
  const [magY, setMagY] = useState<number>(0);
  const dispatch = useAppDispatch();
  const instructionsDisplayed = useAppSelector(selectInstructionsDisplayed);

  const getPixelColorData = useCallback(() => {
    if (color !== "transparent") dispatch(setFullColorData(color));
  }, [dispatch, color]);

  const setInstructionsCallback = useCallback(() => {
    if (instructionsDisplayed) dispatch(setInstructionsDisplayed(false));
  }, [dispatch, instructionsDisplayed]);







  useEffect(() => {
    if (color.length) {
      getPixelColorData();
      setInstructionsCallback();
    }
  }, [getPixelColorData, setInstructionsCallback, color.length]);

  const drawCanvas = useCallback(
    (
      canvas: RefObject<HTMLCanvasElement>,
      url: string,
      canvasSize: CanvasSize,
      isKiosk: boolean,

    ) => {

      setImg(getCanvas(canvas, url, canvasSize, isKiosk));



    },
    []
  );

  const fileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(upload(e));
  };

  const getPixelData = useCallback(
    (
      canvas: RefObject<HTMLCanvasElement>,
      zoomCanvas: RefObject<HTMLCanvasElement>,
      canvasSize: CanvasSize,
      isKiosk: boolean
    ) => {
      handleMouseEvents(
        canvas,
        zoomCanvas,
        setColor,
        setMagX,
        setMagY,
        canvasSize,
        isKiosk
      );
    },
    []
  );

  const value = {
    drawCanvas,
    url,
    fileUpload,
    img,
    getPixelData,
    magX,
    magY,
  };

  return (
    <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
  );
};



const handleMagnifier = (
  mainCanvas: HTMLCanvasElement,
  zoomCanvas: RefObject<HTMLCanvasElement>,
  zoomCtx: CanvasRenderingContext2D,
  eventLocation: {
    x: number;
    y: number;
  },
  isKiosk: boolean
) => {

  let finalSelectionSize: number


  const scale = getDevicePixelRatio();

  zoomCanvas.current!.style.width = zoomCanvas.current!.width + "px";
  zoomCanvas.current!.style.height = zoomCanvas.current!.height + "px";


  finalSelectionSize = selectionSize * scale






  zoomCtx.drawImage(
    mainCanvas,
    eventLocation.x - finalSelectionSize / 2,
    eventLocation.y - finalSelectionSize / 2,
    finalSelectionSize,
    finalSelectionSize,
    0,
    0,
    magnifierSize,
    magnifierSize
  );


  var x = zoomCanvas.current!.width / 2;
  var y = zoomCanvas.current!.height / 2;

  //remove aliasing
  x = Math.floor(x) + 0.5;
  y = Math.floor(y) + 0.5;

  zoomCtx.lineWidth = 2;

  zoomCtx.moveTo(x, y - 10);
  zoomCtx.lineTo(x, y + 10);

  zoomCtx.moveTo(x - 10, y);
  zoomCtx.lineTo(x + 10, y);

  zoomCtx.strokeStyle = "black";

  zoomCtx.stroke();

  zoomCanvas.current!.style.display = "block";
};

function handleMouseEvents(
  canvas: RefObject<HTMLCanvasElement>,
  zoomCanvas: RefObject<HTMLCanvasElement>,
  setColor: React.Dispatch<React.SetStateAction<string>>,
  setMagX: React.Dispatch<React.SetStateAction<number>>,
  setMagY: React.Dispatch<React.SetStateAction<number>>,
  canvasSize: CanvasSize,
  isKiosk: boolean
) {
  const canvasEl = canvas.current;
  const scale = getDevicePixelRatio();
  // Theres a slight offset in magnifier after scaling,this fixes it
  const magnifierAdjustment = 25

  if (canvasEl) {
    canvas.current.addEventListener(
      "pointerdown",
      function (event) {
        mouseIsDown = true;

        var eventLocation = getEventLocation(this, event, isKiosk);
        var context = this.getContext("2d", { willReadFrequently: true });
        var zoomCtx = zoomCanvas.current!.getContext("2d", {
          willReadFrequently: true,
        });




        const magLocationX = eventLocation.x;
        const magLocationY = eventLocation.y;

        // if (!isKiosk) {
        setMagX(((magLocationX - magnifierSize / 2) / scale) - magnifierAdjustment);
        setMagY(((magLocationY - magnifierSize / 2) / scale) - magnifierAdjustment);

        // }
        // else {

        //   setMagX(magLocationX - magnifierSize / 2);
        //   setMagY(magLocationY - magnifierSize / 2);
        // }


        if (zoomCtx !== null)
          handleMagnifier(this, zoomCanvas, zoomCtx, eventLocation, isKiosk);

        if (context !== null) {
          var pixelData = context.getImageData(
            eventLocation.x,
            eventLocation.y,
            1,
            1
          ).data;

          let r = pixelData[0];
          let g = pixelData[1];
          let b = pixelData[2];
          const rgbString = `rgb(${r}, ${g}, ${b})`;
          setColor(rgbString);
        }

        event.preventDefault();
      },
      false
    );
    canvas.current.addEventListener(
      "pointerup",
      function () {
        mouseIsDown = false;
      },
      false
    );
    canvas.current.addEventListener(
      "pointermove",
      function (event) {
        if (mouseIsDown) {
          var eventLocation = getEventLocation(this, event, isKiosk);
          var context = this.getContext("2d", { willReadFrequently: true });
          var zoomCtx = zoomCanvas.current!.getContext("2d", {
            willReadFrequently: true,
          });



          setMagX(((eventLocation.x - magnifierSize / 2) / scale) - magnifierAdjustment);
          setMagY(((eventLocation.y - magnifierSize / 2) / scale) - magnifierAdjustment);

          if (zoomCtx !== null)
            handleMagnifier(this, zoomCanvas, zoomCtx, eventLocation, isKiosk);

          if (context !== null) {
            var pixelData = context.getImageData(
              eventLocation.x,
              eventLocation.y,
              1,
              1
            ).data;

            let r = pixelData[0];
            let g = pixelData[1];
            let b = pixelData[2];
            const rgbString = `rgb(${r}, ${g}, ${b})`;
            setColor(rgbString);
          }
        }
        event.preventDefault();
      },
      false
    );
  }
}

function drawImageScaled(
  img: HTMLImageElement,
  ctx: CanvasRenderingContext2D,
  canvas: RefObject<HTMLCanvasElement>,
  canvasSize: CanvasSize,
  isKiosk: boolean,
) {
  const scale = getDevicePixelRatio();


  const parentContainer = document.querySelector(
    ".color-picker-canvas-parent"
  )!;

  let width: number, height: number, ratio: number;
  if (!isKiosk) {
    width = window.innerWidth;
    height = window.innerHeight / 1.25;

    if (canvasSize === "mobile") {
      width = parentContainer.clientWidth;
    }
  } else {
    width = parentContainer.clientWidth;
    height = window.innerHeight / 1.25;

  }




  canvas.current!.width = width * scale;
  canvas.current!.height = height * scale;



  var hRatio = canvas.current!.width / img.width;
  var vRatio = canvas.current!.height / img.height;
  let centerShiftX, centerShiftY, drawnCanvasX, drawnCanvasY, imgWidth: number, imgHeight: number




  imgWidth = img.width * scale
  imgHeight = img.height * scale
  if (!isKiosk) {

    ratio = Math.max(hRatio, vRatio);


  } else {

    ratio = Math.min(hRatio, vRatio);

  }
  canvas.current!.style.width = width + "px";
  canvas.current!.style.height = height + "px";
  centerShiftX = ((canvas.current!.width - img.width * ratio) / 2) / scale;

  centerShiftY = ((canvas.current!.height - img.height * ratio) / 2) / scale;
  drawnCanvasX = img.width * ratio
  drawnCanvasY = img.height * ratio
  ctx.scale(scale, scale)




  ctx!.clearRect(0, 0, canvas.current!.width, canvas.current!.height);

  ctx!.drawImage(
    img,
    0,
    0,
    imgWidth!, /**width of clipped image */
    imgHeight!,/**height of clipped image */
    centerShiftX,/**x cordinate position of clipped image */
    centerShiftY,/**y cordinate position of clipped image */
    drawnCanvasX,/**new width of clipped image */
    drawnCanvasY /**new height of clipped image */


  );

  if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {


    const boundingCanvasRectangle = canvas.current!.getBoundingClientRect();

    const newClickPositionX = (boundingCanvasRectangle.left + boundingCanvasRectangle.width / 2);
    const newClickPositionY = boundingCanvasRectangle.top + boundingCanvasRectangle.height / 2;


    let clickEvent = new MouseEvent('pointerdown', {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: newClickPositionX, // X-coordinate of the click position
      clientY: newClickPositionY, // Y-coordinate of the click position
    });




    // Dispatch the click event to the element
    canvas.current!.dispatchEvent(clickEvent);

    clickEvent = new MouseEvent('pointerup', {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: newClickPositionX, // X-coordinate of the click position
      clientY: newClickPositionY, // Y-coordinate of the click position
    });

    canvas.current!.dispatchEvent(clickEvent);
  }

}



const getDevicePixelRatio = (): number => {

  return 1; //window.devicePixelRatio;
  //disable device ratio search - causes issues on IOS 18

}
const getCanvas = (
  canvas: RefObject<HTMLCanvasElement>,
  url: string,
  canvasSize: CanvasSize,
  isKiosk: boolean,

): HTMLImageElement | undefined => {
  const savedUrl = sessionStorage.getItem("imageUrl");

  if (canvas.current !== null) {
    var ctx = canvas.current.getContext("2d", { willReadFrequently: true });

    var img = new Image();

    img.onload = () => drawImageScaled(img, ctx!, canvas, canvasSize, isKiosk);

    if (url.length > 0) {
      sessionStorage.setItem("imageUrl", url);

      img.src = url;
    } else {
      img.src = savedUrl!;
    }
    return img;
  }
};



const getElementPosition = (element: HTMLElement) => {
  let top: number = 0;
  let left: number = 0;

  while (element) {
    top += element.offsetTop;
    left += element.offsetLeft;

    element = element.offsetParent as HTMLElement;
  }

  return { x: left, y: top };
};

const getEventLocation = (
  element: HTMLCanvasElement,
  event: PointerEvent | MouseEvent,
  isKiosk: boolean,

): { x: number; y: number } => {
  var position = getElementPosition(element);


  const scale = getDevicePixelRatio();
  // if (!isKiosk) {
  return {
    x: (event.pageX - position!.x) * scale,
    y: (event.pageY - position!.y) * scale
    ,
  };

  // }
  // return {
  //   x: event.pageX - position!.x,
  //   y: event.pageY - position!.y,
  // };
};

const upload = (e: ChangeEvent<HTMLInputElement>) => {
  var url = URL.createObjectURL(e.target.files![0]);
  return url;
};
