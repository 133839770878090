import axios, { AxiosResponse } from "axios";
import { getToken } from "./utils";

const graphqlClient = async () => {
  const token = await getToken();

  const url = "https://checkout.blankbeauty.com/api/2022-10/graphql.json"; //last known working.  Needs to be updated to 2024-07+ before shopify depreciation
  //"https://checkout.blankbeauty.com/api/2024-07/graphql.json";
  // "https://checkout.blankbeauty.com/api/2022-10/graphql.json";
  //"https://blankbeautycheckout.myshopify.com/api/2022-10/graphql.json";
  const options = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": token,
    },
  };
  return {
    post: (data = {}): Promise<AxiosResponse<any, any>> =>
      axios.post(url, data, { ...options }),
  };
};

export const graphqlClientRequest = graphqlClient();
